//En cours de refactorisation
.lock-scroll{
  overflow: hidden!important;
  height:100%!important;
}
:root {
  --primary-color: #39c4d8;
  --secondary-color: #db2836;

  --dark-primary-color: #166672;

  --shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
  --border: #dadada;
  --clear-bg: #F5F5F5;
  --grey-text: #2d3436;
  --dark-text: #2d3436;
  //Navbar
  --navbar_height : 95px;
  --navbar_height_mobile : 63px;


}

$primary_color: #39c4d8;
$secondary_color: #db2836;
$border_grey: #ddd;
$shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
$margin: 30px;
$navbar_height: 95px;
$navbar_height_mobile: 63px;
// Grid System
$tile_width: 300px;
$tile_width_2: 630px;
$tile_height: 250px;
$tile_height_2: 600px;
// notifs
$margin-top-notif1: 50%;
$margin-top-notif2: 19%;
$margin-top-notif3: 27%;
$height_notif1: 100px;
$height_notif2: 400px;
$height_notif3: 100px;
$margin_left_notif1: 3%;
$margin_left_notif2: 35%;
$margin_left_notif3: 45%;
$height_notif: 48px;
