@import "./styles_bulma.scss";
@import "./styles_material.scss";
@import "./styles/fontawesome-scss/fontawesome";
@import "./styles/fontawesome-scss/regular";
@import "./styles/fontawesome-scss/solid";
@import "./styles/fontawesome-scss/brands.scss";
@import "./variables.scss";

@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

.mytiktok {
  width: 320px !important;
  height: 620px !important;
  position: relative;
  -webkit-transform-origin-x: 0%;
  -webkit-transform-origin-y: 0%;
  transition: all 0.5s linear 0s;
}



html {
  scroll-behavior: smooth;
  height: 100%;
}

*:focus {
    outline: none;
}

body {
  height: 100%;
  scroll-behavior: smooth;
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;

  a {
    text-decoration: none;
  }

  ul {
    list-style-type: none;
  }

  .d-flex {
    display: flex;
  }

  .d-none {
    display: none!important;
  }
  .btn {
    cursor: pointer;
    &:hover {
      opacity: 0.9;
    }
  }

  .btn-icon {
    width: 45px;
    height: 45px;
    display: block;
  }

  .fixed {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
  }

  img {
    display: block;
    margin: 0;
    padding: 0;
  }

  iframe {
    width: 100%;
    height: 100%;
  }

  ::-webkit-scrollbar {
    width: .5em;
    height: .5em;
    margin: 2px 0;
  }

  ::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--primary-color);
    border-radius: 5px;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, .2);
  }

  .menu {
    display: flex;
    width: 100vw;
    .fa-ellipsis-h{
      margin-left: 10px;
      font-size:2em;
      color:#d3d3d3;
    }
    .openMenu {
      width: 80px;
      height: 50px;
    }
    .pages {
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      width: calc(100vw - 100px);
      a {
        color: gray;
        font-size: 0.9em;
        text-decoration: none;
        border-bottom: 2px solid var(--selected-page-color, transparent);
        margin-right: 7px;
        &.selected {
          --selected-page-color: gray;
        }
      }

    }
  }
  @media screen and (max-width: 767px){
    app-feedback{
      .btn-edges{
        position: fixed;
        margin-bottom: 50%;
      }
    }
  }
  @media screen and (max-width: 899px){

    .menu{
      .fa-ellipsis-h{
        margin-left: 10px;
        margin-right: 10px;
        font-size: 2em;
        color:#d3d3d3;
        margin-top:20px;

      }
      .openMenu{
        width: 5%;
      }
      .pages{
        width: 100%;
        display: block;
        a{
          font-size: 0.80em;
        }
      }

    }



  }


  .fa-facebook, .fa-facebook-square { color: #3b5998; &:hover { filter: brightness(80%); }}
  .fa-twitter, .fa-twitter-square { color: #00aced; &:hover { filter: brightness(80%); }}

}

body.dark-mode {
  app-grid{
    .imgg-full{
      background: #2d3436;
    }
  }
  .arrow {
    color: white;
  }
  .help{
    .carouseli{
      background-color: red;
    }
  }
  app-feedback{
    .btn-edges{
      background-color: #2d3436!important;
      border: 1px solid;
      border-color: #FFFFFF;
    }
    textarea{
      background-color: #FFFFFF;
      color:#383E42;
    }
    
    .div_close_feedback{
      filter: brightness(100);
    }
    button{
      background-color:#00aced!important;
    }
  }
  background-color: #2d3436!important;
  color: #dfe6e9!important;
  .number_zoom{
    color:white!important;
  }
  app-fast-cart{
    .closedCart{
      background-color: #2d3436!important;
    }
    .cart{
      background-color:#2d3436!important;
      .containerMobile{
        background-color:#2d3436!important;
      }
    }
  }
  app-cart{
    background-color: #2d3436;
    .btn-icon{
      filter: brightness(100)!important;
    }
  }
  app-quickview{
    background-color: #2d3436;
    .btn-close{
      color: white!important;
      filter: brightness(100)!important;
    }
  }
  app-notifications{
    .notif{
      background-color: #2d3436!important ;
    }
  }

  .options-btn{
    .fas{
      background:unset;
      border:unset!important;
    }
  }
   .feedback, .notif_chevron{
    background:unset;
    background-color:#39c4d8;
    border:unset!important;
  }
  app-newtile{
    border:unset!important;
  }
  .fast-cart{
    border-left: unset!important;
    color: #dfe6e9!important;
    background-color: #2d3436!important;
    .container{
      background:#2d3436!important;

      .vider,.ouvrir{
        background-color: #2d3436!important;
      }
    }
    .edge{
      background: unset;
    }
  }
  #navbar{
    background-color: #2d3436!important;
    color: #dfe6e9!important;
    border-bottom: unset!important;
    .box{
      box-shadow: 0px 0px 20px 1px #FFFFFF;
    }
    .box, .box-creat, .creat{
      background: #2d3436!important;
      color: #28c0d7!important;
    }
    .sign-up {
      background: #FFFFFF!important;
    }
    .link{
      color: #FFFFFF;
    }
    p{
      color:#FFFFFF
    }
  }
  #adblocker-turn-off{
    background-color:#2d3436!important;
    .title{
      color:white!important;
    }
    .subtitle{
      color:white!important;
    }
  }
  #footer{
    background-color: #2d3436!important;
    margin-top: 0;
    color: #dfe6e9!important;
    border-bottom: unset!important;
    footer{
      background-color: #2d3436!important;
      margin-top: 0;
    }
    p{
      color:white
    }
  }

  #waves_container{
    background-color: #2d3436;
  }
  .wave-container{
    background-color: #2d3436;
    margin-bottom: 0;
  .wave-header{
    background:#227682!important
  }
    p{
      color:white
    }
  }
  .menu{
    .pages{
      background-color: unset!important;

      a{
        color:white!important;
      }
    }
  }

}

body.light-mode {
  background-color: white;
  app-grid{
    body{
      background-color: white;
    }

  }
  @media screen and (max-width: 899px){

  app-feedback{
    p{
      color: #2d3436;
    }
    .btn-edges{
      background-color: #FFFFFF!important;
      box-shadow: 5px 10px 15px rgba(0, 0, 0, 0.1);
    }
    textarea{
      background-color: #FFFFFF;
      color:#2d3436;
      border: 2px solid;
      border-color: #2d3436;
    }
    
    .div_close_feedback{
      filter: brightness(100);
      .feedback_title{
        position: fixed;
        top: 50%;left: 29%;
        font-size: 25px;
        border-bottom: 1px solid;
        color: black;
        border-color: #00aced;
      }
      .close_feedback {
        width: 30px;
        height: 30px;
        border: 1px solid black;
        padding: 5px;
        box-sizing: border-box;
        border-radius: 50%;
        background-color: black;
      }
    }
    button{
      background-color:#00aced!important;
    }
  }
}
@media screen and (min-width: 1024px){
  app-feedback{
    p{
      color: #2d3436;
    }
    .btn-edges{
      background-color: #FFFFFF!important;
      box-shadow: 5px 10px 15px rgba(0, 0, 0, 0.1);
    }
    textarea{
      background-color: #FFFFFF;
      color:#2d3436;
      border: 2px solid;
      border-color: #2d3436;
    }
    
    .div_close_feedback{
      filter: brightness(100);
      .feedback_title{
        position: fixed;
        top: 50%;
        left: 29%;
        font-size: 25px;
        border-bottom: 1px solid;
        color: black;
        border-color: #00aced;
      }
      .close_feedback {
        width: 30px;
        height: 30px;
        border: 1px solid black;
        padding: 5px;
        box-sizing: border-box;
        border-radius: 50%;
        background-color: black;
      }
    }
    button{
      background-color:#00aced!important;
    }
  }
}
}


